body,
.app {
  min-height: 100vh;
}

.app {
  text-align: center;
  background-image: url('./assets/fondo-bajo-imagen.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.app-logo {
  height: 55px;
  pointer-events: none;
}

.btn-aqua {
  background-color: #58e4cd;
  border-color: #58e4cd;
}

form label {
  font-weight: 600;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 20px;
}

.app-header a {
  color: white;
}
